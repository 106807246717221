var swiper__headerHome = new Swiper(".bannerSwiper", {
    speed: 500,
    spaceBetween: 0,
    loop: "true",
    autoplay: {
        delay: 5000,
    },
    // If we need pagination
    pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true,
    },
});

$(document).on('click','.btnMenu',function(e){
    e.preventDefault();
    var element = $(this).attr('data-link');
    $('html, body').animate({scrollTop: $('.'+element).offset().top - 50}, 800);
});

$(document).on('click','.btn-voltar',function(e){
    e.preventDefault();
    $('html, body').animate({scrollTop: 0}, 800);
});

$(window).scroll(function () {
    if ($(this).scrollTop() > 60) {
        $('header').css('height','60px');
        /*$('header').css('top','-62px');*/
        $('.logotipo img').css({'height':'40px','margin-top':'10px'});
        $('.m-pricipal').addClass('min');
        $('.img-mouse').addClass('d-none');
    } else {
        $('header').css('height','120px');
        /*$('header').css('top','0px');*/
        $('.logotipo img').css({'height':'71px','margin-top':'25px'});
        $('.m-pricipal').removeClass('min');
        $('.img-mouse').removeClass('d-none');
    }
});

$(document).on('click','.check input',function() {
    var check =  $(this);
    if (!check.is(':checked')){
        $('.check img').css('display','none')
    }else{
        $('.check img').css('display','block')
    }
});



$('.pag').click(function(e) { // PAGINAÇÃO
    e.preventDefault();
    var pagenum = $(this).attr('data-pagenum');
    var totalrows = $(this).attr('data-totalrows');
    var busca = $(this).attr('data-form');

    $('.pageNum_RSBusca').val(pagenum);
    $('.totalRows_RSBusca').val(totalrows);

    $('#'+busca).submit();
});